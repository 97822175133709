
<!--维修单明细-->
<template>
  <div class="app-container page-container">
    <div class="section">
      <!--页头-->
      <div class="page-header">
        <div class="ph-left">
          <!--页面标题-->
          <h2 class="page-title">{{ $route.query.number ? $t('equipment_repair_assignment') : $t('Repair_order_details') }}</h2>
        </div>
        <div class="ph-right">
          <el-button size="small" type="" icon="el-icon-back" @click="back">{{ $t('return') }}</el-button>
          <el-button v-if="teamId !== '6c448d56-de6e-48b5-9549-1f8a0022f0e5' && role !== 6" size="small" type="primary" @click="onClickRight">{{ $t('Reason_for_Suspension') }}</el-button>
          <span v-if="role === 2 || role === 3">
            <el-button class="work-button" size="small" type="primary" @click="handleSend" v-if="role === 2 && formList.type === '0' && teamId !== '6c448d56-de6e-48b5-9549-1f8a0022f0e5'">
              {{ $t('assign') }}
            </el-button>
            <el-button class="work-button" size="small" type="primary" @click="handleCompletion" v-else-if="formList.type === '0' && teamId === '6c448d56-de6e-48b5-9549-1f8a0022f0e5'">
              {{ $t('confirm_completion') }}
            </el-button>
          </span>
          <span v-if="formList.type === '1' && role === 6">
            <el-button class="work-button" type="primary" size="small" @click="handleStart" v-if="userId === formList.maintainPersonId">
              {{ $t('start_work') }}
            </el-button>
          </span>
          <span v-if="formList.type === '1'">
            <el-button type="primary" class="work-button" size="small" @click="handleSend" v-if="role === 2">
              {{ $t('reassign') }}
            </el-button>
          </span>


          <!--  解除工单  -->
          <span v-if="formList.type === '7' && role === 6 && !formList.startDate">
            <el-button class="work-button" type="primary" size="small" @click="handleCancelPending">
              {{ $t('resume') }}
            </el-button>
          </span>
          <!--  维修人员 进行中 -->
          <span v-if="formList.type === '2' && role !== 2" class="bottom-button">
            <el-button class="work-button" type="primary" size="small" v-if="userId === formList.maintainPersonId"  @click="handlePending(1)">
              {{ $t('Suspend_work_order') }}
            </el-button>
            <el-button class="work-button" type="primary" size="small" style="right: 0" v-if="userId === formList.maintainPersonId"  @click="handleEnd">
              {{ $t('work_report') }}
            </el-button>
          </span>
          <!--  设备主管 已超时 -->
          <span v-if="formList.type === '6' && role === 2 " class="bottom-button">
            <el-button class="work-button" type="primary" size="small"   @click="handlePendingDialog">
              {{ $t('Suspend_work_order') }}
            </el-button>
            <el-button class="work-button" type="primary" size="small" style="right: 0"   @click="handleSend">
              {{ $t('reassign') }}
            </el-button>
          </span>
          <!--  设备主管 第二次已超时  -->
          <span v-if="formList.type === '7' && role === 2 && type === 1" class="bottom-button">
            <el-button class="work-button" size="small" type="primary"  @click="handlePendingDialog">
              {{ $t('Suspend_work_order') }}
            </el-button>
            <el-button class="work-button" size="small" type="primary" style="right: 0"  @click="handleSend">
              {{ $t('reassign') }}
            </el-button>
          </span>
          <span class="bottom-button" v-if="formList.type === '3'">
            <!--<el-button type="primary" class="work-button" size="small" @click="handleSend" v-if="role === 2">
              {{ $t('reassign') }}
            </el-button>-->
            <el-button type="primary" class="work-button" size="small" v-if="infoList[0].personId === userId && role !== 6" @click="handleConfirm">
              {{ $t('Inspect_maintenance_results') }}
            </el-button>
            <!--   @click="handleConfirmComplete"   -->
            <el-button type="primary" class="work-button" size="small" v-else-if="formList.maintainPersonIds.split(',').includes(userId) && !infoList[0].personId && role !== 6 && infoList[0].repairResult !== $t('Repaired')" @click="handleConfirm">
              {{ $t('Inspect_maintenance_results') }}
            </el-button>
          </span>
        </div>
      </div>
      <div style="display: flex" class="container">
        <div class="form-box" style="border-right: 1px solid #F2F2F2" :style="!isChineseLocale ? 'max-width: 420px;' : 'max-width: 460px;'">
          <el-form
              ref="infoForm"
              :model="formList"
              :label-width="!isChineseLocale ? '120px' : '180px'"
              label-position="left"
          >
            <div class="title">
              <div>{{ $t('basic_information') }}</div>
            </div>
            <el-form-item :label="$t('repair_number')" prop="name">
              <div>{{formList.code}}</div>
            </el-form-item>
            <el-form-item :label="$t('deviceName')" prop="" style="max-width: 1000px">
              <div>{{formList.deviceName}}</div>
            </el-form-item>
            <el-form-item :label="$t('equipment_model')" prop="code">
              <div>{{formList.device}}</div>
            </el-form-item>
            <el-form-item :label="$t('machine_serial_number')" prop="name">
              <div>{{formList.deviceCode}}</div>
            </el-form-item>
            <el-form-item :label="$t('repair_request_time')" prop="name">
              <div>{{formList.repairTime}}</div>
            </el-form-item>
            <el-form-item :label="$t('repair_request_personnel')" prop="name">
              <div>{{formList.repairPerson}}</div>
            </el-form-item>
            <el-form-item :label="$t('repair_personnel')" v-if="teamId !== '6c448d56-de6e-48b5-9549-1f8a0022f0e5'" prop="name">
              <div>{{formList.maintainPerson}}</div>
            </el-form-item>
            <el-form-item :label="$t('device_state')" prop="code">
              <span style="color:#37C239;" v-if="formList.type === '0' && teamId !== '6c448d56-de6e-48b5-9549-1f8a0022f0e5'">{{ $t('assigned') }}</span>
              <span style="color:#F7B515;" v-if="formList.type === '0' && teamId === '6c448d56-de6e-48b5-9549-1f8a0022f0e5'">{{ $t('pending_confirmation') }}</span>
              <span style="color:#EA000E;" v-if="formList.type === '1'">{{ $t('pending_start') }}</span>
              <span style="color:#409eff;" v-if="formList.type === '2'">{{ $t('in_progress') }}</span>
              <span style="color:#F7B515;" v-if="formList.type === '3'">{{ $t('pending_confirmation') }}</span>
              <span style="color:#666666;" v-if="formList.type === '4'">{{ $t('finish') }}</span>
              <span style="color:#999999;" v-if="formList.type === '5'">{{ $t('cancelled') }}</span>
              <span style="color:#EA000E;" v-if="formList.type === '6'">{{ $t('Timed_Out') }}</span>
              <span style="color:#999;" v-if="formList.type === '7'">{{ $t('Suspended') }}</span>
            </el-form-item>
            <div>
              <div class="title">{{ $t('detailed_information')  }}</div>
              <el-form-item :label="$t('fault_type')" prop="code">
                <div>{{formList.repairType}}</div>
              </el-form-item>
              <el-form-item :label="$t('fault_description')" prop="" style="max-width: 1000px">
                <div>{{formList.repairInfo}}</div>
              </el-form-item>
              <el-form-item :label="$t('fault_image')" prop="code" v-if="formList.pictureUrl">
                <div v-if="!formList.pictureUrl">{{ $t('no_image') }}</div>
                <div style="color: #666666;width: 450px;display:block;" class="" v-if="formList.pictureUrl">
                  <div class="group">
                    <el-row :gutter="24"  >
                      <el-col :span="6" v-for="(item,index) in formList.pictureUrl.split(',')" :key="index">
                        <div class="item_img">
                          <el-image
                              style="width: 85%"
                              :src="item"
                              :preview-src-list="formList.pictureUrl.split(',')">
                          </el-image>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-form-item>
              <el-form-item v-if="formList.videoUrl" :label="$t('fault_video')" prop="code">
                <span class="bofang" @click="playVideo">{{ $t('click_play') }}</span>
              </el-form-item>
            </div>

          </el-form>
        </div>
        <div :class="!isChineseLocale ? 'work-report' : 'work-report2'">
          <el-form label-position="left" :label-width="!isChineseLocale ? '110px' : '110px'">
          <div>
            <div v-for="(item, index) in infoList" :key="index">
              <div v-if="infoList.length > 0" class="title">{{ $t('Work_Report') }}</div>
              <el-form-item :label="$t('Hours')" prop="code">
                <div>{{ item.time }}{{$t('hours')}}</div>
              </el-form-item>
              <el-form-item :label="$t('device_repair_time')" prop="code">
                <div>{{item.date}}</div>
              </el-form-item>
              <el-form-item :label="$t('Maintenance_Result')" prop="code">
                <div>{{item.repairResult}}</div>
              </el-form-item>
              <el-form-item :label="$t('Summary')" prop="code">
                <div>{{item.summary}}</div>
              </el-form-item>
              <el-form-item :label="$t('maintenance_person')" v-if="item.username" prop="code">
                <div>{{item.username}}</div>
              </el-form-item>
              <el-form-item :label="$t('image')" v-if="item.pictureUrls" prop="code">
                <div v-if="!item.pictureUrls">{{ $t('no_image') }}</div>
                <div style="color: #666666;width: 450px;display:block;" class="" v-if="item.pictureUrls">
                  <div class="group">
                    <el-row :gutter="20">
                      <el-col :span="6" v-for="(line,lineIndex) in item.pictureUrls.split(',')" :key="lineIndex">
                        <div class="item_img">
                          <el-image
                              style="width: 85%"
                              :src="line"
                              :preview-src-list="item.pictureUrls.split(',')">
                          </el-image>
                          <!--                  <img :src="item" style="width: 100%;" @click="openImg(index)"/>-->
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-form-item>
              <el-form-item :label="$t('video')" prop="code" v-if="item.videoUrl">
                <span class="bofang" @click="playVideo2(item)">{{ $t('click_play') }}</span>
              </el-form-item>
            </div>
            <div v-if="formList.confirmInfo">
              <div class="title acceptance">{{ $t('Acceptance_Info') }}</div>
              <el-form-item :label="$t('Acceptance_Person')" prop="code">
                <div>{{formList.confirmUser}}</div>
              </el-form-item>
              <el-form-item :label="$t('acceptance_results')" prop="" style="max-width: 1000px">
                <div>{{formList.confirmInfo}}</div>
              </el-form-item>
              <el-form-item v-if="formList.remark" :label="$t('Acceptance_Remarks')" prop="code">
                <div>{{formList.remark || '--' }}</div>
              </el-form-item>
              <el-form-item :label="$t('Acceptance_Date')" prop="code">
                <div>{{formList.confirmTime}}</div>
              </el-form-item>
<!--              label-width="200px"-->
              <el-form-item v-if="formList.person" :label="$t('Reassign_repairperson')"  prop="code">
                <div>{{formList.person || '--' }}</div>
              </el-form-item>
            </div>
            <el-form-item v-if="formList.type === '2' && role !== 2" prop="code">
              <div style="text-align: center;color:#F7B515;font-size:28px">
                <span v-if="userId === formList.maintainPersonId">{{time}}</span>
              </div>
            </el-form-item>
          </div>
          </el-form>
        </div>
      </div>

    </div>
    <el-dialog :visible.sync="showVideo"
               :close-on-click-modal="false"
               :modal-append-to-body="false"
               :append-to-body="false"
               @close="close()">
      <div v-if="formList.videoUrl">
        <video ref="videoShow" style="width:100%;height:100%" controls>
          <source :src="formList.videoUrl" type="video/mp4">。
        </video>
      </div>
      <div class="nodata" style="text-align: center" v-else>{{ $t('video_available') }}</div>
    </el-dialog>
    <el-dialog :visible.sync="showVideoInfo"
               :close-on-click-modal="false"
               :modal-append-to-body="false"
               :append-to-body="false"
               @close="close2()">
        <div v-if="videoUrlInfo">
          <video ref="videoShow" style="width:100%;height:100%" controls>
            <source :src="videoUrlInfo" type="video/mp4">。
          </video>
        </div>
        <div class="nodata" style="text-align: center" v-else>{{ $t('video_available') }}</div>
    </el-dialog>
    <!--  人员选择  -->
    <el-dialog :visible.sync="showPerson"
               :title="$t('personnel_selection')"
               :close-on-click-modal="false"
               :modal-append-to-body="false"
               :append-to-body="false"
               width="30%"
               @close="close3()">
      <div>
        <div v-for="(item,index) in teamList" :key="index">
          <div v-if="item.orderType === '1'" class="item" @click="handlePersonSelect(item)">
            <div style="display: flex; align-items: center;">
              <img src="@/assets/img/icon-pen.png" alt="">
              <span>{{ item.cName }}</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :title="$t('acceptance_results')" :show-close="false" :visible.sync="dialogTableVisible" :close-on-click-modal="false"
               width="20%" center>
      <div>
        <el-form :model="form" label-position="top">
          <el-form-item :label="$t('acceptance_results')">
            <el-select v-model="form.info" :placeholder="$t('please_enter_acceptance_results')" style="width: 100%;">
              <el-option
                  v-for="(item, index) in confirmList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('maintenance_person')" v-if="form.info === $t('Not_Repaired') || form.info === $t('Partially_Repaired')">
            <el-select v-model="form.personId" :placeholder="$t('Select_Maintenance_Person')" style="width: 100%;">
              <el-option
                  v-for="(item, index) in teamList"
                  :key="index"
                  :label="item.cName"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('remark')">
            <el-input
                type="text"
                :placeholder="$t('Enter_Remarks')"
                maxlength="20"
                v-model="form.remark">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="dialogTableVisible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="handleCancel">{{ $t('queding') }}</el-button>
      </span>
    </el-dialog>
<!--  工单  -->
    <el-dialog :title="$t('Add_Suspension_Info')" :show-close="false" :visible.sync="PendingDialog" :close-on-click-modal="false"
               width="20%" center>
      <div>
        <el-form ref="pendingForm" label-position="top" :model="pending">
          <el-form-item :label="$t('Reason_for_Suspension')">
            <el-input
                type="text"
                :placeholder="$t('Please_enter_reason_suspension')"
                maxlength="20"
                v-model="pending.reason">
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('Estimated_Repair_Time')">
            <el-date-picker
                style="width: 100%"
                v-model="pending.startDate"
                :picker-options="pickerOptionsStart"
                type="date"
                value-format="yyyy-MM-dd"
                @change="clickstartDate"
                :placeholder="$t('Please_select_estimated_repair_time')">
            </el-date-picker>
            <el-time-select
                style="width: 100%;margin-top: 15px"
                v-model="pending.time"
                :disabled="!pending.startDate"
                :picker-options="computedTimePickerOptions"
                :placeholder="$t('selete_time')">
            </el-time-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="PendingDialog = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="handlePendingDialogConfirm">{{ $t('queding') }}</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import API from '@/api'
import { get_time_diff } from "@/utils/time";
export default {
  name: "faultDetail",
  data() {
    return {
      dialogTableVisible:false,
      PendingDialog: false,
      form: {
        info: '',
        personId: '',
        confirmInfo: '',
        remark: ''
      },
      pending: {
        reason: '',
        startDate: '',
        time: ''
      },
      showPerson:false,// 人员选择弹窗
      flag: null,
      teamList: [],
      hour: 0,
      showVideo: false,
      showVideoInfo: false,
      minute: 0,
      second: 0,
      confirmList: [
        {name: this.$t('Repaired')},
        {name: this.$t('Not_Repaired')},
        {name: this.$t('Partially_Repaired')},
      ],
      formList: {},
      infoList: [],
      videoUrlInfo: '',
      time: '00:00:00',
      role: '',
      userId: '',
      deviceRepairId: this.$route.query.deviceRepairId,
      pickerOptionsStart:{
        disabledDate: time => {
          return time.getTime(new Date()) < Date.now() - 24 * 3600000;
        }
      },
      timePickerOptions: {
        start: '00:00',
        step: '00:30',
        end: '23:30'
      },
      type: 0,
      teamId: ''
    }
  },
  created() {
      setTimeout(() => {
        this.role = this.$store.state.userInfo.roleType
        this.userId = this.$store.state.userInfo.userId
        this.teamId = this.$store.state.userInfo.teamId
        console.log(this.role)
      }, 100);
    this.updateDevice()
    this.teamList = []
    API.teamList({type:3}).then(res => {
      this.teamList.push(...res.message.data)
    })
  },
  computed: {
    isChineseLocale() {
      return localStorage.getItem('locale') === 'en';
    },
    computedTimePickerOptions() {
      const currentTime = new Date();
      const selectedDate = new Date(this.pending.startDate);
      const currentTimeString = currentTime.toTimeString().split(' ')[0];
      const newTimeString = this.addTwoHoursToTimeString(currentTimeString);
      return {
        start: '00:00',
        step: '00:30',
        end: '23:30',
        minTime: selectedDate.toDateString() === currentTime.toDateString()
            ? newTimeString
            : ''
      };

    }
  },
  mounted () {
    this.initTimer();
    this.fetchData()
  },
  beforeRouteUpdate(to, from, next) {
    this.$route.query.deviceRepairId = to.query.deviceRepairId
    this.deviceRepairId = to.query.deviceRepairId; // 更新 deviceRepairId
    this.fetchData(); // 路由参数变化时重新获取数据
    next(); // 继续路由更新
  },
  methods: {
    // 单独企业确认完成
    handleCompletion() {
      this.$confirm(this.$t('current_maintenance_order'), this.$t('prompt'), {
        closeOnClickModal: false,
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      })
          .then(() => {
            API.repairComplete({repairId: this.$route.query.deviceRepairId}).then(res => {
              console.log(res)
              if (res.message.success) {
                this.$message({message: res.message.message,type: 'success',});
                this.getList()
              }
            })
          });

    },
    back() {
      // if (this.$route.query.list) {
      //   this.$router.push({ path: 'maintenanceRecords'});
      // } else {
        this.$router.go(-1)
      // }
    },
    fetchData() {
      // 根据 deviceRepairId 获取数据
      this.updateDevice()
      // 这里可以调用 API 或其他方法来获取数据
    },
    handlePersonSelect(item) {
      API.dispatchUser({id: this.$route.query.deviceRepairId, userId: item.id}).then(res => {
        console.log(res)
        if (res.message.success === true) {
          this.showPerson = false
          this.$message({message: this.$t('operation_successful'),type: 'success',});
          this.updateDevice()
        }
      })

    },
    getTime() {
      console.log(this.formList.startDate)
      const currentTime = new Date();

// 设置要比较的时间
      const targetTime = new Date(this.formList.startDate); // 注意使用 ISO 8601 格式
      console.log(currentTime,targetTime)
// 比较两个时间
      if (currentTime < targetTime) {
        console.log('当前时间早于目标时间');
        this.type = 0
      } else if (currentTime > targetTime) {
        console.log('当前时间晚于目标时间');
        this.type = 1
      } else {
        console.log('当前时间等于目标时间'); // 这种情况在实际应用中很少发生，除非特意设置
      }
    },
    addTwoHoursToTimeString(timeString) {
      // 将时间字符串分割为小时、分钟和秒数
      let [hoursMinSec] = timeString.split(' ');
      let [hours, minutes, seconds] = hoursMinSec.split(':').map(Number);

      // 加两个小时
      hours += 1;

      // 处理小时数超过23的情况（如果需要的话，可以转换为下一天的时间，但这里只考虑24小时制内的时间）
      if (hours >= 24) {
        hours -= 24;
      }

      // 格式化并返回新的时间字符串
      let formattedTime = hours.toString().padStart(2, '0') + ':' +
          minutes.toString().padStart(2, '0') + ':' +
          seconds.toString().padStart(2, '0');
      return formattedTime;
    },
    close3() {
      this.showPerson = false;
    },
    updateDevice() {
      API.searchList({ deviceRepairId: this.$route.query.deviceRepairId }).then(res => {
        this.formList = res.message.data
        this.getTime()
      })
      API.detailList({ deviceRepairId: this.$route.query.deviceRepairId }).then(res => {
        this.infoList = res.message.data
      })

    },
    // 工单挂起
    handlePending(type) {
      console.log(this.$route.query.deviceRepairId)
      console.log(type)
      API.repairPending({id:this.$route.query.deviceRepairId,type:type}).then(res => {
        console.log(res)
        if(res.message.success) {
          this.$message({type:'success', message:res.message.message})
          this.getList()
        }
      })
    },
    // 设备主管挂起弹窗
    handlePendingDialog() {
      this.PendingDialog = true
      this.pending = {
        reason: '',
        startDate: '',
        time: ''
      }
      this.$nextTick(() => {
        // 清除验证
        this.$refs.pendingForm.clearValidate();
      });
    },
    clickstartDate() {
      this.pending.time = ''
    },
    // 设备主管挂起
    handlePendingDialogConfirm() {
      if (!this.pending.reason) {
        this.$message({type:'warning', message:this.$t('Please_enter_reason_suspension')})
        return
      }
      if (!this.pending.startDate) {
        this.$message({type:'warning', message:this.$t('Please_repair_time')})
        return
      }
      if (!this.pending.time) {
        this.$message({type:'warning', message:this.$t('Please_repair_time')})
        return
      }
      const startDate = this.pending.startDate + ' ' + this.pending.time + ':00'
      console.log(this.pending.startDate + ' ' + this.pending.time + ':00')
      console.log(this.pending.time)
      API.repairPending({id:this.$route.query.deviceRepairId,type:2,reason:this.pending.reason,startDate: startDate}).then(res => {
        console.log(res)
        if(res.message.success) {
          this.PendingDialog = false
          this.$message({type:'success', message:res.message.message})
          this.getList()
        }
      })
    },
    // 解除挂起
    handleCancelPending() {
      API.repairCancelPending(this.$route.query.deviceRepairId).then(res => {
        if(res.message.success) {
          this.$message({type:'success', message:res.message.message})
          this.getList()
        }
      })
    },
    // 查看挂起原因
    onClickRight() {
      this.$router.push({ path: 'PendingReasonList', query: { repairId: this.$route.query.deviceRepairId, } })
    },
    initTimer () {
      // 获取当前维修单的保存的时间点（即当前维修单的开始时间点）
      this.flag = setInterval(() => {
        const oldTime = Date.parse(this.formList.startTime) || ""
        // console.log(oldTime)
        // 用当前最新的时间减去获取的时间点
        // 根据相减的结果格式化成time
        const diff = get_time_diff(oldTime)
        // console.log(diff)
        this.time = this.complZero(diff.hours1) +
            ":" +
            this.complZero(diff.minutes1) +
            ":" +
            this.complZero(diff.seconds1);
      })
    },
    handleCancel() {
      if (!this.form.info) {
        this.$message({message: this.$t('please_enter_acceptance_results'),type: 'warning',});
        return
      }
      if (this.form.info === this.$t('Repaired')) {
        this.form.personId = ''
      }
      API.finishWork({repairId:this.$route.query.deviceRepairId,info: this.form.info,personId:this.form.personId,remark:this.form.remark,type:2}).then(res =>{
        this.$message({message: this.$t('order_completed'),type: 'success',});
        this.dialogTableVisible = false
        this.updateDevice()
      })
    },
    //确认完成
    handleConfirm() {
      this.form.info = ''
      this.$forceUpdate()
      this.dialogTableVisible = true
    },
    playVideo () {
      this.showVideo = true;
    },
    close() {
      this.showVideo = false;
    },
    close2() {
      this.showVideoInfo = false
    },
    playVideo2(item) {
      this.videoUrlInfo = item.videoUrl
      this.showVideoInfo = true;
    },
    getList () {
      // getInfo().then(res => {
      //   this.role = res.data.roleType
      // })
      API.searchList({ deviceRepairId: this.$route.query.deviceRepairId }).then(res => {
        // console.log(res)
        this.formList = res.message.data
      })
      API.detailList({ deviceRepairId: this.$route.query.deviceRepairId }).then(res => {
        this.infoList = res.message.data
      })
    },
    handleStart () {
      console.log(this.time)
      API.startWork({ repairId: this.$route.query.deviceRepairId }).then(res => {
        console.log(this.time)
        if (res.message.success === true) {
          this.getList()
          // 改订单状态
          this.$message({message: this.$t('operation_successful'),type: 'success',});
          // 为当前维修单保存当前时间点
          window.localStorage.setItem(this.$route.query.deviceRepairId || "", JSON.stringify(new Date().getTime()))
          this.initTimer()
        }
      })
    },
    // 工作结束
    handleEnd () {
      this.$router.push({ path: 'maintenanceReport', query: { repairId: this.$route.query.deviceRepairId, time: this.time,orderType:this.formList.orderType } })
    },
    // 改派
    handleSend() {
      this.showPerson = true
    },

    complZero (n) {
      return n < 10 ? "0" + n : "" + n;
    },
  }
}
</script>
<style scoped lang="scss">
.widthTable {
  width: 1550px;
}
.title {
  font-size: 18px;
  color: #3D3D3D;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
//.title div:last-child{
//  font-size: 16px;
//  font-weight: 400;
//}
.el-table .hidden-row {
  display: none;
}
.bofang {
  cursor: pointer;
}
.item {
  padding:8px 10px;
img{
  width: 32px;
  margin-right: 10px;
}
}
.item:hover {
  background-color: #FFFAED;
}
.work-button {
  margin-left: 15px;
}
.el-form-item__content{
  div {
    width: 280px;
    word-break: break-all;
  }
}
.work-report {
  padding: 40px 20px 40px 80px;
  height:720px;
  overflow-y: auto;
  .report-item {
    padding-bottom: 80px;
    border-bottom: 1px solid #F2F2F2;
    margin-bottom: 80px;
  }
  .el-form-item {
    display: inline-table;
    width: 367px;
    .el-form-item__content{
      div {
        width: 220px;
        word-break: break-all;
      }
    }
  }
}
.work-report2 {
  padding: 40px 20px 40px 80px;
  height:720px;
  overflow-y: auto;
  .report-item {
    padding-bottom: 80px;
    border-bottom: 1px solid #F2F2F2;
    margin-bottom: 80px;
  }
  .el-form-item {
    display: inline-table;
    width: 350px;
    .el-form-item__content{
      div {
        width: 225px;
        word-break: break-all;
      }
    }
  }
}
.acceptance {
  padding: 60px 0 0;
  border-top: 1px solid #F2F2F2;
  margin-top: 50px;
}
</style>
